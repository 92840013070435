var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-py-3 tw-justify-center"},[(_vm.device.status && _vm.device.status.device_status)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('ax-device-status',{staticClass:"tw-flex tw-flex-col tw-items-center",attrs:{"status":_vm.device.status.device_status,"size":'1.5rem',"show-text":false}})],1)]}}],null,false,1007022704)},[_c('span',[_vm._v(" Device: "),_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(_vm.device.status.device_status))])])]):_vm._e(),(_vm.device.status && _vm.device.status.agent_status)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('ax-agent-connection-status',{staticClass:"tw-flex tw-flex-col tw-items-center tw-mx-2",attrs:{"status":_vm.device.status.agent_status,"size":'1.5rem',"show-text":false}})],1)]}}],null,false,3478074127)},[_c('span',[_vm._v(" Agent: "),_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(_vm.device.status.agent_status))])])]):_vm._e(),(_vm.device.status && _vm.device.status.policy_status)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('ax-policy-status',{staticClass:"tw-flex tw-flex-col tw-items-center",attrs:{"device":_vm.device,"size":'1.5rem',"show-text":false}})],1)]}}],null,false,1851110723)},[_c('span',[_c('span',{staticClass:"tw-capitalize"},[_vm._v("Policy: "+_vm._s(_vm.policyStatus))])])]):_vm._e(),(_vm.device.exception)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',_vm._g({staticClass:"tw-flex tw-flex-col tw-items-center tw-mx-2",attrs:{"color":"neutral","aria-label":"Device: Excluded From Reports","aria-hidden":"false"}},on),[_vm._v(" "+_vm._s(_vm.mdiAlertCircleCheckOutline)+" ")])],1)]}}],null,false,207659154)},[_c('span',[_c('span',{staticClass:"tw-capitalize"},[_vm._v("Device: Excluded From Reports")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }